import { createSlice } from "@reduxjs/toolkit";
import { images } from "../../../assets/imgs";
import { video } from "../../../assets/videos";

const spanish = {
	is: "spanish",
	contact: "CONTACTO",
	imgTitle: images.titleDescktop,
	btnSeeproyects: "Ver proyectos",
	introTextWeAre: "",
	titleWeAreDesktop: {
		p1: "Trabajamos en la",
		p2: "creatividad y ejecución",
		p3: "de proyectos produciendo",
		p4: "eventos y experiencias.",
	},
	ourPropose: "Nuestro propósito",
	wehaveData: "Tenemos data",
	posintsWeHave: {
		p1: "Producción de eventos ",
		p2: "Producción de festivales ",
		p3: "Generación de contenidos audiovisuales",
		p4: "Desarrollo de experiencias para marcas",
		p5: "Diseño e identidad de marca",
	},
	ourCases: "Nuestros casos",
	ourProyects: "Nuestros proyectos",
	proyects: {
		proyect1: {
			title: "Aperol Spritz Live",
			category: "Idea, diseño y producción",
			subtitle: "Idea, diseño y producción de Aperol Spritz Live",
			giff: images.AperolLive_GIF,
			video: video.AperolLiveVIDEOconverted,
		},
		proyect2: {
			title: "Bloomberg NYC",
			category: "Creatividad · Producción integral",
			subtitle: "Producción de la apertura C40 Cities Summit",
			giff: images.BloombergNY_GIF,
			video: video.BloombergNYVIDEO,
		},
		proyect3: {
			category: "Idea, diseño y producción",
			subtitle: "Idea, diseño y producción",
		},
		proyect4: {
			category: "Idea, diseño y producción",
			subtitle: "Idea, diseño y producción Pride Buenos Aires",
		},
		proyect5: {
			category: "BTL · Creatividad · Producción",
			subtitle: "Activacion ¨Fresquita¨",
		},
		proyect6: {
			category: "Diseño y ejecución de stand",
			subtitle: "Diseño y ejecución de stand",
		},
		proyect7: {
			category: "Producción",
			subtitle: "Producción del Aperol District Argentina",
		},
		proyect8: {
			category: "Idea, diseño y producción",
			subtitle: "Idea, diseño y producción audiovisual",
		},
		proyect9: {
			category: "Idea, diseño y ejecución",
			subtitle: "Idea, diseño y ejecución",
		},
		proyect10: {
			category: "BTL · Producción · Creatividad",
			subtitle: "Producción en Primavera Sound",
		},
		proyect11: {
			category: "Producción Integral",
			subtitle: "Producción",
		},
		proyect12: {
			category: "Producción Integral",
			subtitle: "Producción Negroni Challenge",
		},
		proyect13: {
			category: "Idea · Producción Openning",
			subtitle: "Producción",
		},
		//
		proyect14: {
			title: "Campari Argentina",
			category: "Idea · Producción Integral",
			subtitle: "Idea · Producción Integral",
			giff: images.camparigiff,
			video: video.reelEventoCampari,
		},
		proyect15: {
			title: "JD Sports Dinner",
			category: (
				<p className="leading-5 transform -translate-y-4">
					Creatividad <br /> Producción Integral
				</p>
			),
			subtitle: "Creatividad  Producción Integral",
			giff: images.JDgiff,
			video: video.cenajdfamily,
		},
	},
	share: "Compartir",
	theTeammg: images.theTheaMovile,
	maga: {
		role: "Directora Ejecutiva",
		p1: "#Motor  #Cocinera #Ejecutora",
		p2: "#Viajera  #Exploradora",
	},
	galo: {
		role: "Director Creativo",
		p1: "#Creador #Astrólogo #Mago",
		p2: "#Viajero #Bioenergética",
	},
	peter: {
		role: "Director Creativo España",
		p1: "#Curioso #Creativo #Sinapuro",
		p2: "#Conductor #Puente",
	},
	cantactTitle: "¿Necesitas Data?",
	nameAnd: "Nombre",
	mail: "Email",
	phone: "Tel.",
	text: "Mensaje",
	send: "Enviar",
	menuWedo: "QUÉ HACEMOS",
	menuBrandcases: "CÓMO LO HACEMOS",
	menuWho: "QUIENES SOMOS",
};

const english = {
	is: "english",
	contact: "CONTACT US",
	imgTitle: images.titleEnglishDesktop,
	btnSeeproyects: "Our Work",
	introTextWeAre: "",
	titleWeAreDesktop: {
		p1: "We work on",
		p2: "creativity and execution",
		p3: "of projects, producing",
		p4: "events and experiences for brands.",
	},
	ourPropose: "Our purpose:",
	wehaveData: "We have data producing:",
	posintsWeHave: {
		p1: "Events ",
		p2: "Festivals ",
		p3: "Brand Experiences",
		p4: "Design and brand identity",
		p5: "Content Strategy",
	},
	ourCases: "Our cases",
	ourProyects: "Brand cases",
	proyects: {
		proyect1: {
			title: "Aperol Spritz Live",
			category: "Idea, design and production",
			subtitle: "Idea, design, and production of Aperol Spritz Live",
			giff: images.AperolLive_GIF,
			video: video.AperolLiveVIDEOconverted,
		},
		proyect2: {
			title: "Bloomberg NY",
			category: "Full Production",
			subtitle: "Production of the C40 Cities Summit opening",
			giff: images.BloombergNY_GIF,
			video: video.BloombergNYVIDEO,
		},
		proyect3: {
			category: "Idea, design, and production",
			subtitle: "Idea, design, and production",
		},
		proyect4: {
			category: "Idea, design and production",
			subtitle: "Idea, design, and production of Pride Buenos Aires",
		},
		proyect5: {
			category: "creativity · Full Production",
			subtitle: "Fresquita Activation",
		},
		proyect6: {
			category: "Design and execution",
			subtitle: "Design and execution of exhibition booths",
		},
		proyect7: {
			category: "Production",
			subtitle: "Production of Aperol District Argentina",
		},
		proyect8: {
			category: "Idea, design and production",
			subtitle: "Idea, design, and production of audiovisual content",
		},
		proyect9: {
			category: "Idea, design and production",
			subtitle: "Idea, design, and execution",
		},
		proyect10: {
			category: "BTL · production · creativity",
			subtitle: "Production at Primavera Sound",
		},
		proyect11: {
			category: "Full Production",
			subtitle: "Full Production",
		},
		proyect12: {
			category: "Full Production",
			subtitle: "Production Negroni Challenge",
		},
		proyect13: {
			category: "Idea · Producción Openning",
			subtitle: "Production",
		},
		//
		proyect14: {
			title: "Campari Argentina",
			category: "Idea · Integral Production",
			subtitle: "Idea · Integral Production",
			giff: images.camparigiff,
			video: video.reelEventoCampari,
		},
		proyect15: {
			title: "JD Sports Dinner",
			category: (
				<p className="leading-5 transform -translate-y-4">
					Creativity <br /> Full Production
				</p>
			),
			subtitle: "Creativity · Full Production",
			giff: images.JDgiff,
			video: video.cenajdfamily,
		},
	},
	share: "Share",
	theTeammg: images.theTeamMovileEnglish,
	maga: {
		role: "Executive Director",
		p1: "#Driving Force #CHEF #Executor",
		p2: "#Traveler #Explorer",
	},
	galo: {
		role: "Creative Director",
		p1: "#Creator #Astrologer #Magician",
		p2: "#Traveler #Bioenergetic",
	},
	peter: {
		role: "Creative Director Spain",
		p1: "#Curious #Creative #Unhurried",
		p2: "#Driver #Bridge",
	},
	cantactTitle: "How can we help you?",
	nameAnd: "Name",
	mail: "Mail",
	phone: "Phone",
	text: "Text",
	send: "Send",
	menuWedo: "WHAT WE DO",
	menuBrandcases: "BRAND CASES",
	menuWho: "WHO WE ARE",
};

const initialState = {
	isActive: false,
	language: spanish,
};

export const MenuSlice = createSlice({
	name: "Menu",
	// estado inicial definido en la parte superior line:3
	initialState,
	// todas las acciones que puden modificar el store
	reducers: {
		toggleActiveMenu: (state, { payload }) => {
			state.isActive = payload;
		},
		changeToEnglis: (state, { payload }) => {
			state.language = english;
		},
		changeToSpanish: (state, { payload }) => {
			state.language = spanish;
		},
	},
});

export const { toggleActiveMenu, changeToEnglis, changeToSpanish } = MenuSlice.actions;
