import React from 'react'
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { Arrow } from "@egjs/flicking-plugins";
// import "@egjs/flicking-plugins/dist/arrow.css";
import { icons } from '../assets/icons';
import { images } from '../assets/imgs';
import { video } from '../assets/videos';
import { useSelector } from 'react-redux';

export const CarruselProjects = ({ onOpenProyect, setInfoPrject }) => {
    const _plugins = [new Arrow()];
    const { language } = useSelector(state => state.menu)

    return (
        <div className="w-full h-full flex justify-end items-center">
            <Flicking
                align={"prev"}
                circular={true}
                duration={100}
                // onMoveEnd={e => {
                //     console.log(e);
                // }}
                plugins={_plugins}
            >
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: language.proyects.proyect14.title, subtitle: language.proyects.proyect14.subtitle, video: language.proyects.proyect14.video })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2024'} title={language.proyects.proyect14.title} category={language.proyects.proyect14.category} img={language.proyects.proyect14.giff} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: language.proyects.proyect15.title, subtitle: language.proyects.proyect15.subtitle, video: language.proyects.proyect15.video })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2024'} title={language.proyects.proyect15.title} category={language.proyects.proyect15.category} img={language.proyects.proyect15.giff} /></div>

                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "Aperol Spritz @ Primavera Sound", subtitle: language.proyects.proyect10.subtitle, video: video.aperolprimaveravideo })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2023'} title={"Aperol Spritz"} category={language.proyects.proyect10.category} img={images.aperolgiff} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "Desfile Revolver Primavera Verano", subtitle: language.proyects.proyect11.subtitle, video: video.revolvermp4 })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2023'} title={"Revolver Fashion Show"} arrow={false} category={language.proyects.proyect11.category} img={images.revolvergiff} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "Negroni Challenge España", subtitle: language.proyects.proyect12.subtitle, video: video.negronivideo })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2023'} title={"Campari España"} category={language.proyects.proyect12.category} img={images.negroni} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "JD Sports · Opening Marbella Idea", subtitle: language.proyects.proyect13.subtitle, video: video.marbellavideo })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2023'} title={"JD Sports"} category={language.proyects.proyect13.category} img={images.marbellagiff} /></div>
                {/* old */}
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: language.proyects.proyect1.title, subtitle: language.proyects.proyect1.subtitle, video: language.proyects.proyect1.video })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2021'} title={language.proyects.proyect1.title} category={language.proyects.proyect1.category} img={language.proyects.proyect1.giff} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: language.proyects.proyect2.title, subtitle: language.proyects.proyect2.subtitle, video: language.proyects.proyect2.video })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2019'} title={language.proyects.proyect2.title} category={language.proyects.proyect2.category} img={language.proyects.proyect2.giff} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "Skyy - Bresh", subtitle: language.proyects.proyect3.subtitle, video: video.SkyBreshVIDEOconverted })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2019'} title={"Skyy Bresh"} category={language.proyects.proyect3.category} img={images.SkyBresh_GIF} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "Lali - Sony music", subtitle: language.proyects.proyect4.subtitle, video: video.Lali_SonyMusicVIDEOconverted })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2021'} title={"Lali Sony Music"} category={language.proyects.proyect4.category} img={images.Lali_SonyMusic_GIF} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "Andes Origen", subtitle: language.proyects.proyect5.subtitle, video: video.AndesOrigenFresquitaVIDEOconverted })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2019'} title={"Andes Origen"} category={language.proyects.proyect5.category} img={images.AndesOrigen_Fresquita_GIF} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "Feria Oil & Gas: Grupo Techint", subtitle: language.proyects.proyect6.subtitle, video: video.FeriaOilGasGrupoTechintconverted })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2019'} title={"Feria Oil & Gas"} category={language.proyects.proyect6.category} img={images.FeriaOil_GasGrupoTechint_GIF} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "Aperol Spritz", subtitle: language.proyects.proyect7.subtitle, video: video.AperolSpritzDistrictVIDEOconverted })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2021'} title={"Aperol District"} category={language.proyects.proyect7.category} img={images.AperolSpritz_District_GIF} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "Hexacta Sessions", subtitle: language.proyects.proyect8.subtitle, video: video.HexactaSessionsVIDEOconverted })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2019'} title={"Hexacta Sessions"} category={language.proyects.proyect8.category} img={images.HexactaSessions_GIF} /></div>
                <div onClick={
                    () => {
                        onOpenProyect(true)
                        setInfoPrject({ title: "Heineken Bar", subtitle: language.proyects.proyect9.subtitle, video: video.HeinekenBarVIDEOconverted })
                    }
                }><CardProjects colorCard={'bg-D2C5F9'} year={'2019'} title={"Heineken Bar"} category={language.proyects.proyect9.category} img={images.HeiniekenBar__GIF} /></div>
                <ViewportSlot>
                    <span className="flicking-arrow-prev hidden xl:block absolute top-[calc(50%-50px)] z-50 left-4 cursor-pointer">
                        <img src={icons.cardNext} className=" w-[58px] h-[58px] transform rotate-180" alt="" />
                    </span>
                    <span className='hidden xl:block flicking-arrow-next absolute top-[calc(50%-50px)] z-50 right-4 cursor-pointer' >
                        <img src={icons.cardNext} className=" w-[58px] h-[58px]" alt="" />
                    </span>
                </ViewportSlot>
            </Flicking>
        </div>
    )
}

const CardProjects = ({ img, title, isBack = true, colorCard = 'FF521D', category, arrow = true }) => {
    return (
        <div className={`ml-2 h-[492px] xl:h-[510px] 2xl:h-[610px] w-[214px] xl:w-[266px] ${colorCard} rounded-2xl noselect cursor-pointer`}>
            <div className='w-full h-[362px] xl:[370px] 2xl:h-[448px] rounded-t-2xl'>
                <img src={img} className='w-full h-full object-cover object-top rounded-t-2xl' alt="" />
            </div>
            <div className='pl-3 xl:pl-4 pt-4 '>
                <div className='flex  justify-start items-center'>
                    <div className={`border-[1.5px] ${isBack ? 'border-black' : 'border-white'} rounded-lg inline-block px-2 pb-px`}>
                        <p className={`${isBack ? 'text-black' : 'text-white'} 
                        text-[16px] xl:text-[21px]
                         DMSans-Medium`}>{title}</p>
                    </div>
                    {
                        arrow &&
                        <img src={isBack ? icons.arrowAngleUpBlack : icons.arrowAngleUp} className='w-5 h-5 xl:w-6 xl:h-6 ml-1' alt="arrow up angle" />
                    }
                </div>
                <p className={`${isBack ? 'text-black' : 'text-white'} DMSans-Medium text-[13px] xl:text-[16px] pt-[40px] xl:pt-[55px] 2xl:pt-[65px]`}>{category}</p>
            </div>
        </div>
    )
}
