import apero from "./apero.png";
import sky from "./sky.png";
import avena from "./averna.png";
import heineken from "./heineken.png";
import theTeam from "./groupteam.svg";
import theTeamMovileEnglish from "./theteamenglish.svg";
import maga from "./maga.png";
import peter from "./Peter.png";
import galo from "./Galo.png";
import group165 from "./Group165.svg";
import theTheaMovile from "./theteamMovile.svg";
import imgInitAnimation from "./0001.jpg";
import imgFinalAnimation from "./0140.jpg";

import titleDescktop from "./titleDesktop.svg";
import titleEnglishDesktop from "./titleenglish.svg";
import loadingLogo from "./QuieroLaDloading.svg";

import AperolLive_GIF from "./1.AperolLive_GIF.gif";
import BloombergNY_GIF from "./2.BloombergNY_GIF.gif";
import SkyBresh_GIF from "./3.SkyBresh_GIF.gif";
import Lali_SonyMusic_GIF from "./4.Lali_SonyMusic_GIF.gif";
import AndesOrigen_Fresquita_GIF from "./5.AndesOrigen_Fresquita_GIF.gif";
import FeriaOil_GasGrupoTechint_GIF from "./6.FeriaOi_GasGrupTechint_GIF.gif";
import AperolSpritz_District_GIF from "./7.AperolSpritz_District_GIF.gif";
import HexactaSessions_GIF from "./8.HexactaSessions_GIF.gif";
import HeiniekenBar__GIF from "./9.HeiniekenBar__GIF.gif";
import aperolgiff from "./APEROLPRIMAVERAGIF.gif";
import revolvergiff from "./REVOLVERGIF.gif";
import negroni from "./NEGRONI GIF.gif";
import marbellagiff from "./JD Marbella GIF.gif";
import camparigiff from "./Campari.gif";
import JDgiff from "./JD.gif";

export const images = {
	camparigiff,
	JDgiff,
	apero,
	sky,
	loadingLogo,
	titleEnglishDesktop,
	titleDescktop,
	imgFinalAnimation,
	imgInitAnimation,
	theTheaMovile,
	avena,
	heineken,
	theTeam,
	theTeamMovileEnglish,
	group165,
	maga,
	peter,
	galo,
	AperolLive_GIF,
	BloombergNY_GIF,
	SkyBresh_GIF,
	Lali_SonyMusic_GIF,
	AndesOrigen_Fresquita_GIF,
	FeriaOil_GasGrupoTechint_GIF,
	AperolSpritz_District_GIF,
	HexactaSessions_GIF,
	HeiniekenBar__GIF,
	aperolgiff,
	revolvergiff,
	negroni,
	marbellagiff,
};
